import Step from '@/components/steps/Step'
import { Getter, State } from 'vuex-class'

export default abstract class BaseStep extends Step {
  @State('requestId', { namespace: 'regularisation' })
  protected readonly requestId!: string

  @Getter('httpHeaders', { namespace: 'regularisation' })
  protected readonly httpHeaders!: any
}
