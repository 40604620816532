




























import { Prop, Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { RegisterDirection, RegisterInformation } from '@/models'

@Component
export default class MeterRegister extends Vue {
  @Prop({ required: true }) public readonly value!: RegisterInformation
  @Prop({ type: Boolean, required: true }) public readonly isGas!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly required!: boolean

  protected indexData: string = ''

  get meterRegisterDirection(): string {
    return this.value.direction === RegisterDirection.Production ?
      this.$t('common.meterRegister.production').toString() :
      this.$t('common.meterRegister.consumption').toString()
  }

  get isNight(): boolean {
    return this.value.isNight
  }

  get index(): string {
    return this.indexData
  }

  set index(val: string) {
    if (this.readonly) {
      return
    }

    this.indexData = val
    this.$emit('input', val)
  }

  public mounted(): void {
    this.indexData = this.value.value || ''
  }

  @Watch('value')
  public OnValueChanged(): void {
    this.indexData = this.value.value || ''
  }
}
