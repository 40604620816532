

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IsOwnerInfoKnownQuestion extends Vue {
  @Prop({ required: true }) public readonly value!: boolean | null

  public get isInfoKnown(): number | null {
    if (this.value === null || this.value === undefined) {
      return null
    }
    return this.value ? 1 : 0
  }

  public set isInfoKnown(val: number | null) {
    const result = val === null ? val : val === 1
    this.$emit('input', result)
  }
}
