






















import { Prop, Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { MeterEnergyType, RegularisationChoiceData, MeterInformation, RegularisationEanInformation } from '@/models'
import Meter from '@/components/inputs/meter/Meter.vue'
import RegularisationChoices from '@/components/inputs/regularisation/regularisation/RegularisationChoices.vue'

@Component({
  components: {
    Meter,
    RegularisationChoices,
  },
})
export default class RegularisationBox extends Vue {
  @Prop({ required: true }) public readonly value!: RegularisationEanInformation

  public meterInfos: MeterInformation[] = []
  public isChecked: boolean = false
  protected choiceData: RegularisationChoiceData | null = null

  public get IsGas(): boolean {
    return this.EnergyType === MeterEnergyType.gas
  }

  public get EnergyType(): MeterEnergyType {
    return this.value.energyType
  }

  public get choice(): RegularisationChoiceData | null {
    return this.choiceData
  }

  public set choice(data: RegularisationChoiceData | null) {
    if (data !== null) {
      this.isChecked = true
    }
    this.choiceData = data
    this.notifyModelChanged()
  }

  public mounted(): void {
    this.meterInfos = this.getMeterInfos()
    this.choiceData = this.value.choice
    this.isChecked =
      !!this.choiceData || !!this.meterInfos.flatMap((m) => m.registerInfos.flatMap((r) => r.value)).join('')
  }

  public setMeterValue(data: MeterInformation, idx: number): void {
    this.meterInfos[idx] = data
    this.isChecked = true
    this.notifyModelChanged()
  }

  @Watch('isChecked')
  protected unSelect(): void {
    if (!this.isChecked) {
      this.clearIndexes()
      this.meterInfos = this.getMeterInfos()
      this.choice = null
      this.$emit('unSelect')
    }
  }

  // get meter infos with new references
  private getMeterInfos(): MeterInformation[] {
    return this.value.meterInfos.map((mi) => JSON.parse(JSON.stringify(mi)))
  }

  private clearIndexes(): void {
    for (const meter of this.value.meterInfos) {
      for (const reg of meter.registerInfos) {
        reg.value = null
      }
    }
  }

  private notifyModelChanged(): void {
    this.$emit(
      'input',
      Object.assign({}, this.value, {
        meterInfos: this.meterInfos,
        choice: this.choice,
      }),
    )
  }
}
