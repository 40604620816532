























import BaseStep from './BaseStep'
import { Component } from 'vue-property-decorator'
import { Mutation, State, Getter } from 'vuex-class'
import { EnergyProvider, RegularisationEanInformation } from '@/models'
import RegularisationBox from '@/components/inputs/regularisation/regularisation/RegularisationBox.vue'

@Component({
  components: {
    RegularisationBox,
  },
})
export default class RegularisationStep extends BaseStep {
  //#region [Data]
  @State('eanInfos', { namespace: 'regularisation/regularisationMeters' })
  public readonly eanInfosArray!: RegularisationEanInformation[]
  @State('energyProviders', { namespace: 'regularisation/regularisationMeters' })
  protected readonly energyProviders!: EnergyProvider[] | null
  @State('movingDate', { namespace: 'regularisation/regularisationMeters' })
  protected readonly movingDateState!: Date | null

  @Getter('hasEanInfos', { namespace: 'regularisation/regularisationMeters' })
  protected readonly hasEanInfos!: boolean
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_PROVIDERS', { namespace: 'regularisation/regularisationMeters' })
  protected readonly storeProviders!: (data: EnergyProvider[]) => void
  @Mutation('STORE_MOVINGDATE', { namespace: 'regularisation/regularisationMeters' })
  protected readonly storeMovingDate!: (data: Date) => void
  @Mutation('STORE_EANINFOS', { namespace: 'regularisation/regularisationMeters' })
  protected readonly storeEanInfos!: (data: RegularisationEanInformation) => void
  @Mutation('STORE_INFOCACHE', { namespace: 'regularisation/regularisationMeters' })
  protected readonly storeEanInfosCache!: (data: RegularisationEanInformation[]) => void
  @Mutation('CLEAR_EANINFOS', { namespace: 'regularisation/regularisationMeters' })
  protected readonly deleteEanInfos!: (ean: string) => void
  //#endregion

  //#region [Computed]
  get movingDate(): Date | null {
    return this.movingDateState
  }

  set movingDate(data: Date | null) {
    if (data !== null) {
      this.storeMovingDate(data)
    }
  }
  //#endregion

  //#region [Method]
  public async mounted(): Promise<void> {
    this.setNextButtonStatus(this.hasEanInfos)

    if (!this.hasEanInfos) {
      const response = await this.$api
        .get('api/assets/get-eans-by-address', { headers: this.httpHeaders })
        .then((r) => r.data)
      const eanInfos = response.items
      this.storeEanInfosCache(eanInfos)
    }

    if (this.energyProviders === null) {
      const response = await this.$api
        .get('api/regularisation/GetAllEnergyProviders', { headers: this.httpHeaders })
        .then((r) => r.data)
      this.storeProviders(response)
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    return (next && isValid) || !next
  }

  public clearBox(ean: string): void {
    this.deleteEanInfos(ean)
    this.setNextButtonStatus(this.hasEanInfos)
  }

  public setBoxValue(data: RegularisationEanInformation): void {
    this.storeEanInfos(data)
    this.setNextButtonStatus(this.hasEanInfos)
  }
  //#endregion
}
