




















































import { Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { AuthenticateResponse } from '@/models'
import FindMeterLastDigitsModalDialog from '@/components/display/FindMeterLastDigitsModalDialog.vue'
import { helper } from '@/services'

@Component({
  components: {
    FindMeterLastDigitsModalDialog,
  },
})
export default class IdentificationEanMeter extends Vue {
  //#region [Property]
  @Prop({ type: String, required: false, default: null }) public eanCode!: string | null
  @Prop({ type: String, required: false, default: null }) public meterLastFourNumber!: string | null
  @Prop({ type: String, required: true }) public authenticationRoute!: string
  @Prop({ type: Boolean, required: true }) public isIdentified!: boolean
  //#endregion

  //#region [Data]
  public captchaToken: string = ''
  public isFindMeterLastDigitsOpen: boolean = false
  public loading: boolean = false
  public ean: string | null = null
  public meterLastFourNumbers: string | null = null
  public identified: boolean = false
  //#endregion

  //#region [Computed]
  @Watch('ean')
  @Emit('ean')
  public onEanChanged(ean: string | null) {
    return ean
  }

  @Watch('meterLastFourNumbers')
  @Emit('meterLastFourNumbers')
  public onMeterLastFourNumbers(meterLastFourNumbers: string | null) {
    return meterLastFourNumbers
  }

  @Watch('isIdentified')
  public onIsIdentifiedChanged() {
    this.identified = this.isIdentified
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.$nextTick(() => {
      this.ean = this.eanCode ? helper.clone(this.eanCode) : null
      this.meterLastFourNumbers = this.meterLastFourNumber ? helper.clone(this.meterLastFourNumber) : null
      this.identified = this.isIdentified ? true : false
    })
  }

  public async identify(): Promise<void> {
    if (!this.ean || !this.meterLastFourNumbers) {
      return
    }

    this.loading = true
    const headers = { 'g-recaptcha-response': this.captchaToken }
    const payLoad = { ean: this.ean, meterLastFourNumbers: this.meterLastFourNumbers }
    try {
      const response = await this.$api.post<AuthenticateResponse>(this.authenticationRoute, payLoad, {
        headers,
      })

      if (response.status >= 200 && response.status < 300) {
        this.$emit('eanInfo', response.data)
      }
      this.identified = true
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('500') || error.message.includes('400')) {
          this.identified = false
        } else {
          this.identified = true
        }
      }
      this.$emit('eanInfo', null)
    } finally {
      this.loading = false
    }

    this.$emit('isIdentified', this.identified)
  }
  //#endregion
}
