

























import BaseStep from './BaseStep'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import SummaryIdentification from '@/components/inputs/regularisation/summary/steps/SummaryIdentification.vue'
import SummaryContact from '@/components/inputs/regularisation/summary/steps/SummaryContact.vue'
import SummaryOwner from '@/components/inputs/regularisation/summary/steps/SummaryOwner.vue'
import SummaryRegularisation from '@/components/inputs/regularisation/summary/steps/SummaryRegularisation.vue'
import SignatureArea from '@/components/inputs/misc/SignatureArea.vue'
import { RegularisationContactInfos, OwnerInfo, MeterEnergyType, RegularisationEanInformation } from '@/models'

@Component({
  components: {
    'summary-step-identification': SummaryIdentification,
    'summary-step-contact': SummaryContact,
    'summary-step-owner': SummaryOwner,
    'summary-step-regularisation': SummaryRegularisation,
    SignatureArea,
  },
})
export default class SummaryStep extends BaseStep {
  @Prop({ type: Boolean, required: false, default: false }) public IsActive!: boolean

  public stepData: Array<{ id: number; step: number; name: string }> = [
    { id: 1, step: 1, name: 'identification' },
    { id: 2, step: 3, name: 'contact' },
    { id: 3, step: 4, name: 'owner' },
    { id: 4, step: 5, name: 'regularisation' },
  ]

  @State('lang')
  protected readonly lang!: string
  @State('contactInfos', { namespace: 'regularisation/contact' })
  protected readonly contactInfosState!: RegularisationContactInfos | null
  @State('isSameAsClientInfo', { namespace: 'regularisation/owner' })
  protected readonly isSameAsClientInfoState!: boolean
  @State('isOwnerInfoKnown', { namespace: 'regularisation/owner' })
  protected readonly isOwnerInfoKnownState!: boolean
  @State('ownerInfos', { namespace: 'regularisation/owner' })
  protected readonly ownerInfosState!: OwnerInfo | null
  @State('movingDate', { namespace: 'regularisation/regularisationMeters' })
  protected readonly movingDateState!: Date
  @State('eanInfos', { namespace: 'regularisation/regularisationMeters' })
  protected readonly eanInfosArray!: RegularisationEanInformation[]

  protected requestData: FormData | null = null

  public mounted(): void {
    this.setNextButtonStatus(false)
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (!next) {
      this.setNextButtonStatus(true)
      return true
    }

    if (!this.requestData) {
      return false
    }

    const header = { headers: this.httpHeaders }

    let formerAddress = null
    if (!!this.contactInfosState!.formerAddress) {
      formerAddress = {
        street: this.contactInfosState!.formerAddress!.streetSubCity?.name,
        houseNumber: this.contactInfosState!.formerAddress!.houseNumber,
        houseBox: this.contactInfosState!.formerAddress!.houseBox,
        zipCode: this.contactInfosState!.formerAddress!.subCity?.zipCode,
        subCity: this.contactInfosState!.formerAddress!.subCity?.cityName,
        country: this.contactInfosState!.formerAddress!.country,
      }
    }

    let billingAddress = null
    if (!this.contactInfosState!.isSameBillingAddress) {
      billingAddress = {
        street: this.contactInfosState!.billingAddress!.streetSubCity?.name,
        houseNumber: this.contactInfosState!.billingAddress!.houseNumber,
        houseBox: this.contactInfosState!.billingAddress!.houseBox,
        zipCode: this.contactInfosState!.billingAddress!.subCity?.zipCode,
        subCity: this.contactInfosState!.billingAddress!.subCity?.cityName,
        country: this.contactInfosState!.billingAddress!.country,
      }
    }

    let ownerAddress = null
    if (!!this.ownerInfosState!.address) {
      ownerAddress = {
        street: this.ownerInfosState!.address!.streetSubCity?.name,
        houseNumber: this.ownerInfosState!.address!.houseNumber,
        houseBox: this.ownerInfosState!.address!.houseBox,
        zipCode: this.ownerInfosState!.address!.subCity?.zipCode,
        subCity: this.ownerInfosState!.address!.subCity?.cityName,
        country: this.ownerInfosState!.address!.country,
      }
    }

    const filledEan = this.eanInfosArray.filter((info) => !!info.choice)
    const regularisationData = {
      language: this.lang,
      contactInfos: {
        contactBaseInfo: this.contactInfosState!.contactBaseInfo,
        isSameBillingAddress: this.contactInfosState!.isSameBillingAddress,
        billingAddress,
        formerAddress,
      },
      isOwnerSameAddress: this.isSameAsClientInfoState,
      isOwnerInfoKnown: this.isOwnerInfoKnownState,
      ownerInfos: this.isOwnerInfoKnownState
        ? {
            contactBaseInfo: this.ownerInfosState!.contactBaseInfo,
            address: ownerAddress,
          }
        : null,
      movingDate: this.movingDateState,
      eanInfos: filledEan,
    }

    this.requestData.set('regularisationData', JSON.stringify(regularisationData))
    this.setNextButtonStatus(false)
    let isError = false
    await this.$api.post('api/regularisation/Confirmation', this.requestData, header).catch(() => {
      this.setNextButtonStatus(true)
      isError = true
    })
    if (isError) {
      return false
    }
    this.setNextButtonStatus(true)

    this.$appInsights.trackEvent('Regularisation_SummaryStep', {
      Segment: 'Regularisation',
      WebRef: this.requestId,
      EanCount: `${filledEan.length}`,
      EanElecCount: `${filledEan.filter((d) => d.energyType === MeterEnergyType.electricity).length}`,
      EanGasCount: `${filledEan.filter((d) => d.energyType === MeterEnergyType.gas).length}`,
    })

    return true
  }

  public goToStep(step: number): void {
    this.setNextButtonStatus(true)
    this.$emit('goToStep', step)
  }

  public onNewSignature(data: FormData | null): void {
    this.requestData = data
    this.setNextButtonStatus(!!data)
  }

  @Watch('IsActive')
  public OnIsActiveChanged(isActive: boolean): void {
    if (isActive) {
      this.setNextButtonStatus(!!this.requestData)
    }
  }
}
