




































import { Vue, Component } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { OwnerInfo } from '@/models'
import { contactService } from '@/services'
import SummarySectionContent from '@/components/inputs/regularisation/summary/SummarySectionContent.vue'
import SummaryContactInfos from '@/components/inputs/regularisation/summary/SummaryContactInfos.vue'

@Component({
  name: 'summary-step-owner',
  components: {
    SummarySectionContent,
    SummaryContactInfos,
  },
})
export default class SummaryOwner extends Vue {
  @State('isSameAsClientInfo', { namespace: 'regularisation/owner' })
  public readonly isSameAsClientInfo!: boolean
  @State('isOwnerInfoKnown', { namespace: 'regularisation/owner' })
  public readonly isInfoKnown!: boolean | null
  @State('ownerInfos', { namespace: 'regularisation/owner' })
  public readonly contactInfos!: OwnerInfo | null

  get formattedOwnerAddress(): string {
    return this.contactInfos?.address ? contactService.getFormattedAddress(this.contactInfos?.address) : ''
  }
}
