










import { Component, Prop, Vue } from 'vue-property-decorator'
import { OwnerInfo } from '@/models';
import ContactBaseForm from '@/components/inputs/contact/ContactBaseForm.vue';
import AddressForm from '@/components/inputs/contact/AddressForm.vue';

@Component({
  components: {
    ContactBaseForm,
    AddressForm
  },
})
export default class OwnerInfos extends Vue {
  @Prop({ required: true }) public readonly value!: OwnerInfo | null
}
