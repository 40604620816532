








import { Vue, Component } from 'vue-property-decorator'
import EanAddress from '@/components/inputs/regularisation/shared/EanAddress.vue'
import SummarySectionContent from '@/components/inputs/regularisation/summary/SummarySectionContent.vue'
import { Getter, State } from 'vuex-class'
import { MeterEnergyType } from '@/models'

@Component({
  name: 'summary-step-identification',
  components: {
    EanAddress,
    SummarySectionContent
  }
})
export default class SummaryIdentification extends Vue {
  //#region [Data]
  @State('ean', { namespace: 'regularisation/identification' })
  public readonly ean!: string
  @State('energyType', { namespace: 'regularisation/identification' })
  public readonly energyType!: MeterEnergyType

  @Getter('formattedAddress', { namespace: 'regularisation/identification' })
  public readonly formattedAddress!: string
  //#endregion
}
