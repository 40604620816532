





















import { Vue, Component } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { ConsumptionAddress, RegularisationContactInfos } from '@/models'
import { contactService } from '@/services'
import SummarySectionContent from '@/components/inputs/regularisation/summary/SummarySectionContent.vue'
import SummaryContactInfos from '@/components/inputs/regularisation/summary/SummaryContactInfos.vue'

@Component({
  name: 'summary-step-contact',
  components: {
    SummarySectionContent,
    SummaryContactInfos,
  },
})
export default class SummaryContact extends Vue {
  //#region [Data]
  @State('contactInfos', { namespace: 'regularisation/contact' })
  public readonly contactInfos!: RegularisationContactInfos
  @State('address', { namespace: 'regularisation/identification' })
  public readonly consumptionAddress!: ConsumptionAddress
  //#endregion

  //#region [Computed]
  get formattedBillingAddress(): string {
    if (this.contactInfos.isSameBillingAddress) {
      return contactService.getFormattedConsumptionAddress(this.consumptionAddress)
    }
    return contactService.getFormattedAddress(this.contactInfos.billingAddress)
  }

  get formattedFormerAddress(): string {
    return contactService.getFormattedAddress(this.contactInfos.formerAddress)
  }
  //#endregion
}
