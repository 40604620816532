











import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { MeterInformation, RegisterInformation } from '@/models'
import MeterRegister from '@/components/inputs/meter/MeterRegister.vue'

@Component({
  components: {
    MeterRegister,
  },
})
export default class Meter extends Vue {
  @Prop({ required: true }) public readonly value!: MeterInformation
  @Prop({ type: Boolean, required: true }) public readonly isGas!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly showProductionRegister!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly required!: boolean

  public indexes: RegisterInformation[] = []

  protected get val(): MeterInformation {
    return Object.assign({}, this.value, { registerInfos: this.indexes })
  }

  public setIndexValue(value: string, idx: number): void {
    if (this.readonly) {
      return
    }

    if (!this.indexes.length) {
      this.indexes = this.value.registerInfos.slice(0)
    }
    this.indexes[idx].value = value
    this.$emit('input', this.val)
  }
}
