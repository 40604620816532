





















































import { Component, Vue } from 'vue-property-decorator'
import SignaturePad from 'signature_pad'
import { FileInfo, FileStatus } from '@ores/vue-library'

@Component
export default class SignatureArea extends Vue {
  // https://github.com/szimek/signature_pad/blob/gh-pages/js/app.js

  public signatureFile: FileInfo[] = []
  protected signaturePad: SignaturePad | null = null
  protected signatureFormData: FormData | null = null
  private isSigning: boolean = false

  protected get canvas(): HTMLCanvasElement {
    return this.$el.getElementsByTagName('canvas')[0] as HTMLCanvasElement
  }

  protected get hasSignature(): boolean {
    return !this.signaturePad?.isEmpty() || !!this.signatureFile.length
  }

  protected get signature(): FormData | null {
    return this.signatureFormData
  }

  protected set signature(val: FormData | null) {
    this.signatureFormData = val
    this.$emit('input', val)
  }

  private get fieldset(): HTMLFieldSetElement {
    return this.$el.getElementsByTagName('fieldset')[0] as HTMLFieldSetElement
  }

  public mounted(): void {
    this.signaturePad = new SignaturePad(this.canvas)
    this.setCanvasSize()
    window.addEventListener('resize', this.setCanvasSize)

    // set flag when start signing
    this.signaturePad.addEventListener('beginStroke', () => (this.isSigning = true))

    // save signature when mouse leave signature zone
    this.canvas.addEventListener('mouseleave', this.onSignatureUpdate)
  }

  public uploadFiles(files: FormData): Promise<{ data: FileStatus[] }> {
    const entries = files.getAll('file').slice(0, 1) as File[]

    if (!entries.length || !entries[0]) {
      return Promise.resolve({ data: [] })
    }

    const data = entries.map((entry) => ({
      fileName: entry.name,
      error: null,
      updatedFileName: URL.createObjectURL(entry),
    }))
    this.clearPad()
    this.setSignatureData(entries[0])
    return Promise.resolve({ data })
  }

  public deleteFiles(files: { data: string[] }): Promise<void> {
    this.clear()
    return Promise.resolve()
  }

  public clear(): void {
    this.clearPad()
    this.clearFile()
    this.setSignatureData(null)
  }

  protected setCanvasSize(): void {
    if (!this.signaturePad) {
      return
    }

    // use all fieldset inner space
    this.canvas.width = this.fieldset.clientWidth

    // must clear pad or isEmpty() might be faulty
    this.clearPad()
  }

  protected async onSignatureUpdate(event: any): Promise<void> {
    if (!this.isSigning) {
      // avoid to update signature when mouse is just passing by
      return
    }

    this.isSigning = false
    this.clearFile()

    this.canvas.toBlob((blob) => {
      if (blob === null) {
        this.setSignatureData(null)
      } else {
        this.setSignatureData(new File([blob], 'signature.png'))
      }
    })
  }

  protected clearPad(): void {
    this.signaturePad?.clear()
  }

  protected clearFile(): void {
    this.signatureFile = []
  }

  private setSignatureData(file: File | null) {
    if (file === null) {
      this.signature = null
    } else {
      const formData = new FormData()
      formData.set('signature', file)
      this.signature = formData
    }
  }
}
