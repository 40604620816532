



































import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import EanAddress from '@/components/inputs/regularisation/shared/EanAddress.vue'
import { MeterEnergyType } from '@/models'
import { chatService } from '@/services'

@Component({
  components: {
    EanAddress
  },
})
export default class GetAddressStatus extends Vue {
  //#region [Property]
  @Prop({ type: String, required: false, default: null }) public energyType!: MeterEnergyType | null
  @Prop({ type: String, required: false, default: null }) public formattedAddress!: string | null
  @Prop({ type: String, required: true }) public eanCode!: string
  @Prop({ type: String, required: true }) public meterLastFourNumbers!: string
  //#endregion

  //#region [Method]
  public openChat(): void {
    chatService.openChat()
  }
  //#endregion
}
