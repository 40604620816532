













import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { MeterEnergyType } from '@/models'
import EnergyInfoBox from '@/components/inputs/regularisation/shared/EnergyInfoBox.vue'

@Component({
  components: {
    EnergyInfoBox,
  },
})
export default class EanAddress extends Vue {
  @Prop({ type: String, required: false, default: null }) public ean!: string | null
  @Prop({ type: String, required: false, default: null }) public energyType!: MeterEnergyType | null
  @Prop({ type: String, required: false, default: null }) public formattedAddress!: string | null
}
