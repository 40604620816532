








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SummarySectionContent extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) public readonly borderless!: boolean;
}
