





























import BaseStep from './BaseStep'
import { Component, Emit, Watch } from 'vue-property-decorator'
import IdentificationEanMeter from '@/components/inputs/regularisation/identification/IdentificationEanMeter.vue'
import GetAddressStatus from '@/components/inputs/regularisation/identification/GetAddressStatus.vue'
import { Action, Mutation, State } from 'vuex-class'
import { AuthenticateResponse, ConsumptionAddress, MeterEnergyType } from '@/models'
import { contactService, helper } from '@/services'

@Component({
  components: {
    IdentificationEanMeter,
    GetAddressStatus,
  },
})
export default class IdentificationStep extends BaseStep {
  //#region [Data]
  @State('ean', { namespace: 'regularisation/identification' })
  public readonly eanState!: string | null
  @State('meterLastFourNumbers', { namespace: 'regularisation/identification' })
  public readonly meterLastFourNumbersState!: string | null
  @State('energyType', { namespace: 'regularisation/identification' })
  public readonly energyTypeState!: MeterEnergyType | null
  @State('address', { namespace: 'regularisation/identification' })
  public readonly addressState!: ConsumptionAddress | null

  public ean: string | null = null
  public meterLastFourNumbers: string | null = null
  public energyType: MeterEnergyType | null = null
  public address: ConsumptionAddress | null = null
  public eanInfo: AuthenticateResponse | null = null

  public isIdentified: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  public clearApiErrors!: () => void
  @Mutation('STORE_EAN', { namespace: 'regularisation/identification' })
  public readonly storeEan!: (data: string | null) => void
  @Mutation('STORE_METERLASTFOURNUMBERS', { namespace: 'regularisation/identification' })
  public readonly storeMeterLastFourNumbers!: (data: string | null) => void
  @Action('STORE_IDENTIFICATION', { namespace: 'regularisation' })
  public readonly storeResponse!: (data: AuthenticateResponse) => Promise<void>
  @Action('RESET', { namespace: 'regularisation' })
  public readonly reset!: () => Promise<void>
  //#endregion

  //#region [Method]
  public mounted() {
    this.ean = this.eanState ? helper.clone(this.eanState) : null
    this.meterLastFourNumbers = this.meterLastFourNumbersState ? helper.clone(this.meterLastFourNumbersState) : null
    this.energyType = this.energyTypeState ? helper.clone(this.energyTypeState) : null
    this.address = this.addressState ? helper.clone(this.addressState) : null
    this.$nextTick(() => {
      this.onEanChanged(this.ean)
      this.onIsIdentifiedChanged()
    })
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid) {
      // log AppInsight
    }
    this.storeEan(this.ean)
    this.storeMeterLastFourNumbers(this.meterLastFourNumbers)
    if (this.eanInfo) {
      this.storeResponse(this.eanInfo)
    }
  }
  //#endregion

  //#region [Computed]
  public get formattedAddress(): string {
    return contactService.getFormattedConsumptionAddress(this.address)
  }
  //#endregion

  //#region [Watch]
  @Watch('eanInfo')
  public onEanInfoChanged() {
    if (this.eanInfo) {
      this.address = this.eanInfo.address
      this.energyType = this.eanInfo.energyType
    } else {
      this.address = null
      this.energyType = null
    }
  }

  @Watch('ean')
  public async onEanChanged(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.eanState && this.addressState) {
      // User input is the same as last identified EAN
      this.isIdentified = true
    } else {
      await this.resetSession()
    }
  }

  @Watch('meterLastFourNumbers')
  public async onMeterLastFourNumbersChanged(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.meterLastFourNumbersState && this.addressState) {
      // User input is the same as last identified meter number
      this.isIdentified = true
    } else {
      await this.resetSession()
    }
  }

  @Emit('canContinue')
  @Watch('isIdentified')
  public onIsIdentifiedChanged() {
    return this.isIdentified && !!this.address
  }

  public async resetSession() {
    this.isIdentified = false
    await this.reset()
  }
  //#endregion
}
