


























































































import { Form } from '@/views/forms/Form'
import { Getter, State, Action } from 'vuex-class'
import { Component, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import {
  ApiErrorNotification,
  StepDefinition,
  RegularisationContactInfos,
  OwnerInfo,
} from '@/models'
import { EanInformation } from '@/models/api'
import ApiErrors from '@/components/display/ApiErrors.vue'
import StepActions from '@/components/shared/StepActions.vue'
import IdentificationStep from '@/components/steps/regularisation/IdentificationStep.vue'
import RequesterStatusStep from '@/components/steps/regularisation/RequesterStatusStep.vue'
import ContactStep from '@/components/steps/regularisation/ContactStep.vue'
import OwnerStep from '@/components/steps/regularisation/OwnerStep.vue'
import RegularisationStep from '@/components/steps/regularisation/RegularisationStep.vue'
import SummaryStep from '@/components/steps/regularisation/SummaryStep.vue'
import SendConfirmation from '@/components/display/SendConfirmation.vue'

@Component({
  name: 'regularisation',
  components: {
    ApiErrors,
    StepActions,
    IdentificationStep,
    RequesterStatusStep,
    ContactStep,
    OwnerStep,
    RegularisationStep,
    SummaryStep,
    SendConfirmation,
  },
})
export default class Regularisation extends Form {
  //#region [Data]
  @State('apiErrors', { namespace: 'apiErrors' })
  public readonly apiErrors!: ApiErrorNotification | null
  @State('requestId', { namespace: 'regularisation' })
  public readonly requestId!: string
  @Getter('hasEanInfos', { namespace: 'regularisation/regularisationMeters' })
  public readonly hasEanInfos!: boolean
  @Getter('email', { namespace: 'regularisation/contact' })
  public readonly email!: string

  @State('contactInfos', { namespace: 'regularisation/contact' })
  public readonly contactInfosState!: RegularisationContactInfos | null
  @State('isSameAsClientInfo', { namespace: 'regularisation/owner' })
  public readonly isSameAsClientInfoState!: boolean
  @State('ownerInfos', { namespace: 'regularisation/owner' })
  public readonly ownerInfosState!: OwnerInfo | null
  @State('movingDate', { namespace: 'regularisation/regularisationMeters' })
  public readonly movingDateState!: Date
  @State('eanInfos', { namespace: 'regularisation/regularisationMeters' })
  public readonly eanInfosArray!: EanInformation[]

  @State('ean', { namespace: 'regularisation/identification' })
  public readonly eanState!: string | null

  public isSummary: boolean = false

  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('identification', 1),
    new StepDefinition('status', 2),
    new StepDefinition('contact', 3),
    new StepDefinition('owner', 4),
    new StepDefinition('regularisation', 5),
    new StepDefinition('summary', 6),
    new StepDefinition('confirmation', 7),
  ] as StepDefinition[]

  @Action('CLEAR_STEPS', { namespace: 'regularisation' })
  public clearSteps!: (step: number) => Promise<void>
  //#endregion

  //#region [Method]
  public getStepComponent(step: number): Step | null {
    switch (step) {
      case 1:
        return this.$refs.step1 as Step
      case 2:
        return this.$refs.step2 as Step
      case 3:
        return this.$refs.step3 as Step
      case 4:
        return this.$refs.step4 as Step
      case 5:
        return this.$refs.step5 as Step
      case 6:
        return this.$refs.step6 as Step
      case 7:
        return this.$refs.step7 as Step
      default:
        return null
    }
  }

  public async goToPrevious(): Promise<void> {
    await this.modifyStep(this.currentStep - 1)
  }

  public async modifyStep(step: number): Promise<void> {
    this.goToStep(step)
  }

  public mounted(): void {
    sessionStorage.clear()
    this.onLangChanged(this.lang)
    this.currentStep = 1
    this.lastStepSeen = 1
    this.stepToGoAfterValidation = 6
    this.stepsToValidate = [1, 2]
  }

  public beforeCreate(): void {
    document.title = this.$t('regularisation.formTitle').toString()
  }

  public created() {
    this.CheckMaintenance('regularisation')
  }

  public async backToSummary(summaryStep: number): Promise<void> {
    const step = this.getStepComponent(this.currentStep)
    const result = await step?.change(true)
    if (result === 1) {
      this.currentStep = summaryStep
    }
  }
  //#endregion

  //#region [Computed]
  get hasNext(): boolean {
    switch (this.currentStep) {
      case 5:
        return this.hasEanInfos
      default:
        return this.currentStep < this.lastStep
    }
  }

  get hasPrevious(): boolean {
    return this.currentStep > 1 && this.currentStep < 7
  }

  get nextLabel(): string {
    switch (this.currentStep) {
      case 6:
        return this.$t('common.send').toString()
      default:
        return this.$t('common.nextButton').toString()
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('eanState')
  public async onEanStateChanged(newValue: string | null, oldValue: string | null) {
    if (newValue === null) {
      return
    }

    this.ensureLastStep(2, newValue === oldValue)

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('contactInfosState', { deep: true })
  @Watch('isSameAsClientInfo')
  @Watch('ownerInfos', { deep: true })
  @Watch('movingDate')
  @Watch('eanInfosArray', { deep: true })
  public resetIsSummary(): void {
    this.isSummary = false
  }

  @Watch('currentStep')
  public setIsSummary(): void {
    if (this.currentStep === 6) {
      this.isSummary = true
    }
  }

  @Watch('requestId')
  public onRequestIdChanged(): void {
    if (!this.requestId) {
      this.currentStep = 1
      this.lastStepSeen = 1
    }
  }
  //#endregion
}
