












































import { Prop, Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { EnergyProvider, MeterEnergyType, RegularisationChoiceData, RegularisationChoiceType } from '@/models'
import { State } from 'vuex-class'

@Component
export default class RegularisationChoices extends Vue {
  @Prop({ required: true }) public readonly value!: RegularisationChoiceData | null
  @Prop({ type: Boolean, required: false, default: false }) public readonly required!: boolean
  @Prop({ required: true }) public readonly energyType!: MeterEnergyType

  @State('energyProviders', { namespace: 'regularisation/regularisationMeters' })
  public readonly energyProvidersState!: EnergyProvider[] | null

  public options: any[] = []
  protected choiceType: number | null = null

  public get choice(): number | null {
    return this.choiceType
  }

  public set choice(data: number | null) {
    if (data === null) {
      return
    }
    this.choiceType = data
    this.options = this.options.map((opt) => Object.assign({}, opt, { energyProvider: null }))
    this.notifyModelChanged()
  }

  public get energyProviderList(): EnergyProvider[] {
    const type = this.energyType === MeterEnergyType.electricity ? 'E' : 'G'
    return this.energyProvidersState?.filter((p) => p.energyType === type) || []
  }

  public mounted(): void {
    this.setInitialState()
  }

  @Watch('value')
  public setValue() {
    this.choiceType = this.value?.choiceType ?? null
    this.setInitialState()
  }

  public setProviderData(id: number, provider: EnergyProvider, contract: string): void {
    this.options = this.options.map((opt) =>
      Object.assign({}, opt, {
        energyProvider: opt.item === id ? provider : null,
        contractReference: opt.item === id ? contract : null,
      }),
    )
    this.choiceType = id
    this.notifyModelChanged()
  }

  private notifyModelChanged(): void {
    if (this.choice === null) {
      return
    }

    const opt = this.options.find((o) => o.item === this.choice)
    const energyProvider = opt?.energyProvider
    const contractReference = opt?.contractReference
    const payload = { choiceType: this.choice, energyProvider, contractReference }
    this.$emit('input', payload)
  }

  private setInitialState(): void {
    const enumValues = Object.values(RegularisationChoiceType)
      .map((str) => str as number)
      .filter((h) => !isNaN(h))
    this.options = []
    for (const i of enumValues) {
      const tmp = {
        item: i,
        label: `regularisation.regularisationChoice.${i}.label`,
        description: i < 4 ? `regularisation.regularisationChoice.${i}.description` : null,
        withNote: i === 1 || i === 3,
        withProvider: i < 4,
        energyProvider: this.choiceType === i ? this.value?.energyProvider : null,
        withContract: i === 1,
        contractReference: this.choiceType === i ? this.value?.contractReference : null,
      }
      this.options.push(tmp)
    }
  }
}
