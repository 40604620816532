





















































import { Vue, Component } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { RegularisationEanInformation } from '@/models'
import SummarySectionContent from '@/components/inputs/regularisation/summary/SummarySectionContent.vue'
import EnergyInfoBox from '@/components/inputs/regularisation/shared/EnergyInfoBox.vue'
import { regularisationService } from '@/services'
import Meter from '@/components/inputs/meter/Meter.vue'

@Component({
  name: 'summary-step-regularisation',
  components: {
    SummarySectionContent,
    EnergyInfoBox,
    Meter,
  },
})
export default class SummaryRegularisation extends Vue {
  @State('movingDate', { namespace: 'regularisation/regularisationMeters' })
  public readonly movingDateState!: Date | null
  @State('eanInfos', { namespace: 'regularisation/regularisationMeters' })
  public readonly eanInfos!: RegularisationEanInformation[]

  public get movingDate(): string {
    return this.movingDateState?.toDateString() ?? ''
  }

  public getChoiceLabel(eanInfo: RegularisationEanInformation): string {
    const type = eanInfo.choice?.choiceType
    if (type === undefined) {
      return ''
    }
    return this.$t(`regularisation.regularisationChoice.${type}.label`).toString()
  }

  public getInfoBoxLevel(eanInfo: RegularisationEanInformation): string {
    return regularisationService.getInfoBoxLevel(eanInfo.energyType)
  }

  public isSet(eanInfo: RegularisationEanInformation): boolean {
    return regularisationService.isRegularisationEanInfosSet(eanInfo)
  }
}
