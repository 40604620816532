














































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RegularisationContactInfos } from '@/models'
import SummarySectionContent from '@/components/inputs/regularisation/summary/SummarySectionContent.vue'
import { dateService } from '@/services'

@Component({
  components: {
    SummarySectionContent,
  },
})
export default class SummaryContactInfos extends Vue {
  //#region [Property]
  @Prop({ required: true, type: Object })
  public readonly contactInfos!: RegularisationContactInfos
  @Prop({ type: Boolean, default: true })
  public readonly showType!: boolean
  //#endregion

  //#region [Computed]
  public get contactName(): string {
    if (this.contactInfos && this.contactInfos.contactBaseInfo) {
      let civility = null
      if (this.contactInfos.contactBaseInfo.civility === 1) {
        civility = this.$t('contact.civility.mr')
      } else if (this.contactInfos.contactBaseInfo.civility === 2) {
        civility = this.$t('contact.civility.mrs')
      }
      return `${civility} ${this.contactInfos.contactBaseInfo.firstName} ${this.contactInfos.contactBaseInfo.lastName}`
    }
    return ''
  }

  get companyInfo(): string | undefined | null {
    const contact = this.contactInfos?.contactBaseInfo
    if (contact?.contactType === 'publicAuthority') {
      return `${this.$t('common.contact.publicAuthorityType.' + contact?.companyType)} ${contact?.companyName ?? ''}`
    } else if (contact?.contactType === 'freelanceOrCompany') {
      return `${this.$t('common.contact.companyType.' + contact?.companyType)} ${contact?.companyName ?? ''}`
    }
  }

  get contactVat(): string | undefined | null {
    return this.contactInfos?.contactBaseInfo?.vatNumber?.trim()
  }

  get birthDate(): string | null {
    return this.contactInfos?.contactBaseInfo?.birthdate ?
      dateService.displayDate(this.contactInfos?.contactBaseInfo?.birthdate) :
      null
  }

  //#endregion
}
