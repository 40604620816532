













import BaseStep from './BaseStep'
import { Component } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import { ContactAddress, RegularisationContactInfos } from '@/models'
import ContactInfos from '@/components/inputs/regularisation/contact/ContactInfos.vue'
import { contactService } from '@/services'

@Component({
  components: {
    ContactInfos,
  },
})
export default class ContactStep extends BaseStep {
  @State('address', { namespace: 'regularisation/identification' })
  public readonly eanAddress!: ContactAddress

  public contactModel: RegularisationContactInfos = {
      contactBaseInfo: contactService.createContactBase(),
      formerAddress: contactService.createContactAddress(),
      billingAddress: contactService.createContactAddress(),
      isSameBillingAddress: true,
    }

  @State('contactInfos', { namespace: 'regularisation/contact' })
  protected readonly regularisationContactInfos!: RegularisationContactInfos
  @Mutation('STORE_CONTACTINFOS', { namespace: 'regularisation/contact' })
  protected storeContactInfos!: (data: RegularisationContactInfos) => Promise<void>

  public mounted(): void {
    this.setNextButtonStatus(true)
    if (this.regularisationContactInfos) {
      this.contactModel = this.regularisationContactInfos
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid) {
      // log app insight
    }

    await this.storeContactInfos(this.contactModel)
  }
}
