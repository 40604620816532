

















import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { MeterEnergyType } from '@/models'
import { regularisationService } from '@/services'

@Component
export default class EnergyInfoBox extends Vue {
  @Prop({ type: String, required: true }) public readonly ean!: string
  @Prop({ type: [Object, String], required: true }) public readonly energyType!: MeterEnergyType

  get isElectricity(): boolean {
    return this.energyType === MeterEnergyType.electricity
  }

  get isGas(): boolean {
    return this.energyType === MeterEnergyType.gas
  }

  get energyLevel(): string {
    return regularisationService.getInfoBoxLevel(this.energyType)
  }
}
