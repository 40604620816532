































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConsumptionAddress, RegularisationContactInfos } from '@/models'
import { contactService } from '@/services'
import ContactBaseForm from '@/components/inputs/contact/ContactBaseForm.vue'
import AddressForm from '@/components/inputs/contact/AddressForm.vue'

@Component({
  components: {
    ContactBaseForm,
    AddressForm,
  },
})
export default class ContactInfos extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: RegularisationContactInfos
  @Prop({ type: Object, required: true }) public readonly eanAddress!: ConsumptionAddress
  //#endregion

  //#region [Data]
  public useOtherBillingAddressData: number = 0
  //#endregion

  //#region [Computed]
  get useOtherBillingAddress(): number {
    return this.useOtherBillingAddressData
  }

  set useOtherBillingAddress(data: number) {
    const isSame = data === 0
    this.useOtherBillingAddressData = data
    this.value.isSameBillingAddress = isSame

    if (isSame) {
      this.value.billingAddress = null
    } else if (!this.value.billingAddress) {
      this.value.billingAddress = contactService.createContactAddress()
    }
  }

  get formattedAddress(): string {
    return contactService.getFormattedConsumptionAddress(this.eanAddress)
  }

  get isSameBillingAddress(): boolean {
    return this.value.isSameBillingAddress
  }
  //#endregion

  @Watch('isSameBillingAddress')
  public onValueChanged() {
    this.useOtherBillingAddress = this.isSameBillingAddress ? 0 : 1
  }
}
