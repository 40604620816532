

















import BaseStep from './BaseStep'
import { Component } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import { OwnerInfo } from '@/models'
import OwnerInfos from '@/components/inputs/contact/owner/OwnerInfos.vue'
import { contactService, helper } from '@/services'
import IsSameAsClientInfoQuestion from '@/components/inputs/contact/owner/IsSameAsClientInfoQuestion.vue'
import IsOwnerInfoKnownQuestion from '@/components/inputs/contact/owner/IsOwnerInfoKnownQuestion.vue'

@Component({
  components: {
    OwnerInfos,
    IsSameAsClientInfoQuestion,
    IsOwnerInfoKnownQuestion,
  },
})
export default class OwnerStep extends BaseStep {
  //#region [Data]
  @State('isSameAsClientInfo', { namespace: 'regularisation/owner' })
  public readonly isSameAsClientInfoState!: boolean | null
  @State('isOwnerInfoKnown', { namespace: 'regularisation/owner' })
  public readonly isInfoKnownState!: boolean | null
  @State('ownerInfos', { namespace: 'regularisation/owner' })
  public readonly regularisationOwnerInfosState!: OwnerInfo

  public isSameAsClientInfo: boolean | null = null
  public isInfoKnown: boolean | null = null
  public regularisationOwnerInfos: OwnerInfo = {
    contactBaseInfo: contactService.createContactBase(),
    address: contactService.createContactAddress(),
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_ISSAME', { namespace: 'regularisation/owner' })
  public readonly storeIsSame!: (data: boolean) => void
  @Mutation('STORE_OWNERKNOWN', { namespace: 'regularisation/owner' })
  public readonly storeIsInfoKnown!: (data: boolean) => void
  @Mutation('STORE_OWNERINFOS', { namespace: 'regularisation/owner' })
  public readonly storeOwnerInfos!: (data: OwnerInfo) => void
  @Mutation('CLEAR_OWNERINFOS', { namespace: 'regularisation/owner' })
  public readonly clearOwnerInfos!: () => void
  //#endregion

  //#region [Method]
  public mounted(): void {
    this.setNextButtonStatus(true)
    this.regularisationOwnerInfos = helper.clone(this.regularisationOwnerInfosState)
    if (this.isSameAsClientInfoState !== null) {
      this.isSameAsClientInfo = this.isSameAsClientInfoState
    }
    if (this.isInfoKnownState !== null) {
      this.isInfoKnown = this.isInfoKnownState
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid && this.isSameAsClientInfo !== null) {
      // log app insight

      this.storeIsSame(this.isSameAsClientInfo)
      this.storeIsInfoKnown(this.isInfoKnown === true)
      if (this.isSameAsClientInfo) {
        this.clearOwnerInfos()
      } else {
        this.storeOwnerInfos(this.regularisationOwnerInfos)
      }
    }
  }
  //#endregion
}
