










































import { Component } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import BaseStep from './BaseStep'

@Component
export default class RequesterStatusStep extends BaseStep {
  //#region [Data]
  @State('isPro', { namespace: 'regularisation/contact' })
  public readonly isPro!: boolean | null
  public status: boolean | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_ISPRO', { namespace: 'regularisation/contact' })
  public readonly storeIsPro!: (data: boolean | null) => void
  //#endregion

  //#region [Method]
  public mounted(): void {
    this.setNextButtonStatus(true)
    this.status = this.isPro
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid) {
      // log AppInsight
      this.storeIsPro(this.status)
      return true
    }
    return !next
  }
  //#endregion
}
